import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  Content,
  Flex,
  Heading,
  Text,
  Button,
  useDialogContainer,
} from "@adobe/react-spectrum";
import Table from "../Common/Table";

const MultipleMergeResponseDialog = ({ mergeResponseData }) => {
  const dialog = useDialogContainer();
  const columns = [
    { name: "Org Entity Id", uid: "org_entity_id" },
    { name: "Account Name", uid: "org_name" },
    { name: "Address", uid: "address" },
    { name: "Duns Id", uid: "duns_Id" },
    { name: "Website", uid: "website" },
  ];

  const rows = [
    {
      id: 1,
      org_entity_id: mergeResponseData.org_entity.org_entity_id,
      org_name: mergeResponseData.org_entity.org_name_1,
      address: `${mergeResponseData.org_entity.street_1} ${mergeResponseData.org_entity.street_2} ${mergeResponseData.org_entity.postalcode} ${mergeResponseData.org_entity.city} ${mergeResponseData.org_entity.state}`,
      duns_Id: mergeResponseData.org_entity.duns_number,
      website: mergeResponseData.org_entity.website,
    },
  ];

  const renderCell = (colKey, row) => (
    <span // eslint-disable-line jsx-a11y/no-static-element-interactions
      style={{ cursor: "text", WebkitUserSelect: "text" }}
      onPointerDown={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Text>{row[colKey]}</Text>
    </span>
  );

  return (
    <Dialog width="70rem">
      <Content>
        <Flex direction="column" gap="size-125">
          <Heading
            level={3}
            marginStart="size-300"
            marginTop="size-300"
            UNSAFE_style={{ color: "green" }}
          >
            Merge Successful
          </Heading>
          <Flex
            direction="column"
            marginStart="size-300"
            marginEnd="size-300"
            marginTop="size-10"
            marginBottom="size-300"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={columns}
              rows={rows}
              renderCell={renderCell}
              selectionMode="none"
              density="compact"
            />
          </Flex>
          <Flex alignItems="end" justifyContent="flex-end" marginStart="auto">
            <Button variant="secondary" onPress={dialog.dismiss}>
              Close
            </Button>
          </Flex>
        </Flex>
      </Content>
    </Dialog>
  );
};

MultipleMergeResponseDialog.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mergeResponseData: PropTypes.object.isRequired,
};

export default MultipleMergeResponseDialog;
