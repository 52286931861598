import {
  Flex,
  Text,
  useAsyncList,
  Link as V3Link,
  TooltipTrigger,
  ActionButton,
  View,
  Tooltip,
  DialogContainer,
  SearchField,
} from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import {
  error as ErrorToast,
  success as SuccessToast,
} from "@react/react-spectrum/Toast";
import { useOktaAuth } from "@okta/okta-react";
import Magnify from "@spectrum-icons/workflow/Magnify";
import { ManualReviewAccountsColumn } from "../../../constants/Columns";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import LoadingDialog from "../../../components/Dialog/LoadingDialog";
import ViewSuspectsDialog from "../../../components/Dialog/HMS/ViewSuspectsDialog";
import Table from "../../../components/Common/Table";
import { OrgEntityAccountsLabel } from "../../../constants/SlidingPaneLabels";
import CamSlidingPane from "../../../components/Common/CamSlidingPane/CamSlidingPane";
import AccountMappingForm from "../../../components/Forms/AccountMappingForm/AccountMappingForm";
import MultipleMergeResponseDialog from "../../../components/Dialog/MultipleMergeResponseDialog";
import useValidUserAccess from "../../../hooks/useValidUserAccess";
import { ADMIN } from "../../../constants/RoleType";
import { HMS } from "../../../constants/CamFunctions";

export default function ManualReview() {
  const [fetchReviewListRequest, setFetchReviewListRequest] = useState({
    match_queue_type: "account_grouping",
    page: 0,
    size: 10,
  });
  const [searchedId, setSearchedId] = useState();
  const [isSearchLoading, setSearchLoading] = useState("");

  const [slidingPaneData, setSlidingPaneData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const [viewSuspects, setViewSuspects] = useState(false);
  const [viewSuspectsData, setViewSuspectsData] = useState();

  const [isPageLoading, setPageLoading] = useState(false);
  const { authState } = useOktaAuth();

  const reviewListSearchResultSize = 10;

  // edit access
  const isHmsEditActionAllowed = useValidUserAccess([ADMIN], HMS);
  let goldenRecordsColumn = ManualReviewAccountsColumn();
  if (isHmsEditActionAllowed)
    goldenRecordsColumn = [
      ...goldenRecordsColumn,
      {
        name: "Review",
        uid: "review",
        defaultWidth: 95,
      },
    ];

  const handleOpenDialog = (data) => {
    setSlidingPaneData(data);
    setOpenDialog(!openDialog);
  };

  const handleReviewGrouping = (row) => {
    setViewSuspectsData(row);
    setViewSuspects(true);
  };

  const orgAccountList = useAsyncList({
    initialFilterText: "",
    async load({ filterText, cursor }) {
      let json = {
        results: [],
      };
      const request = cursor || fetchReviewListRequest;
      if (filterText !== "") {
        setSearchLoading("loading");
        json = await goldenRecordApi.fetchManualReviewList(
          request,
          authState.accessToken
        );
      }
      const results = json?.results.pending_records || [];

      setSearchLoading("");
      return {
        items: results,
        cursor:
          results.length < reviewListSearchResultSize
            ? null
            : {
                ...fetchReviewListRequest,
                page: json?.results.next_page,
              },
      };
    },
    getKey: (item) => item?.id,
  });

  useEffect(() => {
    orgAccountList.setFilterText(fetchReviewListRequest);
  }, [fetchReviewListRequest]);

  // state to control the dialog visibility
  const [viewMultipleMergeChanges, setViewMultipleMergeChanges] =
    useState(false);
  const [mergeResponseData, setMergeResponseData] = useState(null);

  const handleMergeSuspect = (mergeSuspectRequest) => {
    setPageLoading(true);
    goldenRecordApi
      .mergeSuspect(mergeSuspectRequest, authState.accessToken)
      .then((data) => {
        setPageLoading(false);
        if (data?.status) {
          setMergeResponseData(data);
          setViewMultipleMergeChanges(true); // Open the dialog
          setFetchReviewListRequest({
            match_queue_type: "account_grouping",
            page: 0,
            size: 10,
          });
        } else {
          ErrorToast(
            data?.error_message && data?.message
              ? `${data?.error_message} (${data?.message})`
              : "Failed to merge accounts!",
            {
              timeout: 5000,
            }
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to merge accounts!",
          {
            timeout: 5000,
          }
        );
      });
  };

  const handleDiscardSuspect = (discardSuspectRequest) => {
    setPageLoading(true);
    goldenRecordApi
      .discardSuspect(discardSuspectRequest, authState.accessToken)
      .then((data) => {
        setPageLoading(false);
        if (data?.status) {
          SuccessToast("Suspects Successfully Discarded!", {
            timeout: 5000,
          });
          setViewMultipleMergeChanges(false);
          setFetchReviewListRequest({
            match_queue_type: "account_grouping",
            page: 0,
            size: 10,
          });
        } else {
          ErrorToast(
            `${data?.error_message} (${data?.message})` ||
              "Failed to merge accounts!",
            {
              timeout: 5000,
            }
          );
        }
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to discard suspects!",
          {
            timeout: 5000,
          }
        );
      });
  };

  const renderCell = (colKey, row) => {
    if (colKey === "address" || colKey === "address_key") {
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{ cursor: "text", WebkitUserSelect: "text" }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>
            {`${row.street ? row.street : ""} ${
              row.street_sup ? row.street_sup : ""
            } ${row.city ? row.city : ""}, ${row.state ? row.state : ""} ${
              row.country ? row.country : ""
            } ${row.postalcode ? row.postalcode : ""}`}
          </Text>
        </span>
      );
    }
    if (colKey === "review") {
      return (
        <TooltipTrigger>
          <ActionButton isQuiet onPress={() => handleReviewGrouping(row)}>
            <View>
              <Magnify color="informative" />
            </View>
          </ActionButton>
          <Tooltip>Review Account Grouping</Tooltip>
        </TooltipTrigger>
      );
    }
    if (colKey === "org_entity_id") {
      return (
        <Text>
          <V3Link isQuiet onPress={() => handleOpenDialog(row)}>
            {row[colKey]}
          </V3Link>
        </Text>
      );
    }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  return (
    <Flex marginTop="size-100" direction="column">
      <LoadingDialog isOpen={isPageLoading} />
      <Flex gap="size-100" wrap>
        <SearchField
          value={searchedId}
          onChange={setSearchedId}
          onSubmit={() => {
            setFetchReviewListRequest({
              ...fetchReviewListRequest,
              org_entity_id: searchedId,
            });
          }}
          onClear={() =>
            setFetchReviewListRequest({
              match_queue_type: "account_grouping",
              page: 0,
              size: 10,
            })
          }
          label={
            <Flex height="32px" alignItems="center">
              <Flex width="200px">
                <Text UNSAFE_style={{ fontSize: "14px" }}>Search by orgid</Text>
              </Flex>
            </Flex>
          }
          width="300px"
        />
      </Flex>
      <Flex
        marginTop="size-250"
        UNSAFE_className="stepper-box"
        UNSAFE_style={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        height="static-size-6000"
      >
        <Table
          columns={goldenRecordsColumn}
          rows={orgAccountList.items}
          loadingState={
            isSearchLoading !== ""
              ? isSearchLoading
              : orgAccountList.loadingState
          }
          onLoadMore={orgAccountList.loadMore}
          renderCell={renderCell}
          selectionMode="none"
          density="compact"
        />
      </Flex>
      <DialogContainer
        onDismiss={() => setViewSuspects(false)}
        type="fullscreen"
      >
        {viewSuspects && (
          <ViewSuspectsDialog
            currentOrgInfo={viewSuspectsData}
            handleMergeSuspect={handleMergeSuspect}
            handleDiscardSuspect={handleDiscardSuspect}
          />
        )}
      </DialogContainer>
      {openDialog && (
        <CamSlidingPane
          isPaneOpenFlag={openDialog}
          handleOpenDialog={() => setOpenDialog(false)}
          paneTitle={slidingPaneData.account_name}
          Component={
            <AccountMappingForm
              data={slidingPaneData}
              labels={OrgEntityAccountsLabel()}
            />
          }
        />
      )}

      <DialogContainer onDismiss={() => setViewMultipleMergeChanges(false)}>
        {viewMultipleMergeChanges && (
          <MultipleMergeResponseDialog mergeResponseData={mergeResponseData} />
        )}
      </DialogContainer>
    </Flex>
  );
}
