import {
  Button,
  Flex,
  Dialog,
  Heading,
  Divider,
  Content,
  useDialogContainer,
  ButtonGroup,
  Header,
  ActionButton,
  Text,
} from "@adobe/react-spectrum";
import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  error as ErrorToast,
  // success as SuccessToast,
} from "@react/react-spectrum/Toast";
import Close from "@spectrum-icons/workflow/Close";
import PropTypes from "prop-types";
import Table from "../../Common/Table";
import { goldenRecordApi } from "../../../api/goldenRecordApi";
import { ManualReviewAccountsColumn } from "../../../constants/Columns";
import LoadingDialog from "../LoadingDialog";

export default function ViewSuspectsDialog({
  currentOrgInfo,
  handleMergeSuspect,
  handleDiscardSuspect,
}) {
  const dialog = useDialogContainer();
  const [selectedSuspect, setSelectedSuspect] = useState(new Set([]));
  const [suspects, setSuspects] = useState([]);
  const [isPageLoading, setPageLoading] = useState(false);
  const cols = ManualReviewAccountsColumn();
  const { authState } = useOktaAuth();

  useEffect(() => {
    goldenRecordApi
      .fetchSuspects(
        {
          match_queue_type: "account_grouping",
          record_id: currentOrgInfo.org_entity_id,
        },
        authState.accessToken
      )
      .then((data) => {
        setPageLoading(false);
        setSuspects(data.suspects);
      })
      .catch((error) => {
        setPageLoading(false);
        ErrorToast(
          error.response?.data?.message || "Failed to fetch suspect list!",
          {
            timeout: 5000,
          }
        );
      });
  }, [currentOrgInfo.org_entity_id]);

  const renderCell = (colKey, row) => {
    if (colKey === "address" || colKey === "address_key") {
      return (
        <span // eslint-disable-line jsx-a11y/no-static-element-interactions
          style={{
            cursor: "text",
            userSelect: "text",
            WebkitUserSelect: "text",
          }}
          onPointerDown={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <Text>
            {`${row.street ? row.street : ""} ${
              row.street_sup ? row.street_sup : ""
            } ${row.city ? row.city : ""}, ${row.state ? row.state : ""} ${
              row.country ? row.country : ""
            } ${row.postalcode ? row.postalcode : ""}`}
          </Text>
        </span>
      );
    }
    // if ((colKey === "org_entity_id")) {
    //     return (
    //       <Text>
    //         <V3Link isQuiet onPress={() => handleOpenDialog(row)}>
    //           {row[colKey]}
    //         </V3Link>
    //       </Text>
    //     );
    // }
    return (
      <span // eslint-disable-line jsx-a11y/no-static-element-interactions
        style={{ cursor: "text", WebkitUserSelect: "text" }}
        onPointerDown={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Text>{row[colKey]}</Text>
      </span>
    );
  };

  return (
    <Dialog>
      <LoadingDialog isOpen={isPageLoading} />
      <Heading>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="size-100"
          padding="size-100"
        >
          <Header>{`Suspect List for orgid: ${currentOrgInfo.org_entity_id}`}</Header>
          <Flex>
            <ButtonGroup>
              <ActionButton
                variant="secondary"
                onPress={dialog.dismiss}
                isQuiet
              >
                <Close />
              </ActionButton>
            </ButtonGroup>
          </Flex>
        </Flex>
      </Heading>
      <Divider />
      <Content>
        <Flex direction="column">
          {/* current match */}
          <Flex direction="row" marginBottom="10px">
            <Header marginTop="10px">Current Match</Header>
          </Flex>
          <Flex
            direction="column"
            gap="size-125"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={cols}
              rows={[currentOrgInfo]}
              selectionMode="none"
              renderCell={renderCell}
              density="compact"
            />
          </Flex>
          {/* suspect list */}
          <Divider size="M" marginTop="size-600" />
          <Flex direction="row" marginTop="10px" marginBottom="10px">
            <Header marginTop="10px">Suspect Records</Header>
            <Flex alignItems="end" marginStart="auto">
              <ButtonGroup>
                <Button
                  color="primary"
                  variant="accent"
                  onPress={() => {
                    handleMergeSuspect({
                      match_queue_type: "account_grouping",
                      original_org_entity_id: currentOrgInfo.org_entity_id,
                      merge_with_org_entity_ids: Array.from(selectedSuspect),
                      _id: currentOrgInfo._id, // eslint-disable-line no-param-reassign, no-underscore-dangle
                    });
                    dialog.dismiss();
                  }}
                  isDisabled={
                    suspects.length === 0 || selectedSuspect.size === 0
                  }
                >
                  Merge
                </Button>
                <Button
                  variant="secondary"
                  onPress={() => {
                    handleDiscardSuspect({
                      _id: currentOrgInfo._id, // eslint-disable-line no-param-reassign, no-underscore-dangle
                    });
                    dialog.dismiss();
                  }}
                >
                  Reject
                </Button>
              </ButtonGroup>
            </Flex>
          </Flex>

          <Flex
            direction="column"
            gap="size-125"
            UNSAFE_style={{
              backgroundColor: "white",
              borderRadius: "0.5rem",
            }}
            UNSAFE_className="stepper-box"
          >
            <Table
              columns={cols}
              rows={suspects}
              renderCell={renderCell}
              density="compact"
              selectionMode="multiple"
              selectedKeys={selectedSuspect}
              onSelectionChange={setSelectedSuspect}
            />
          </Flex>
        </Flex>
      </Content>
    </Dialog>
  );
}

ViewSuspectsDialog.propTypes = {
  currentOrgInfo: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.array,
      PropTypes.objectOf(PropTypes.string),
      PropTypes.any,
    ])
  ).isRequired,
  handleMergeSuspect: PropTypes.func.isRequired,
  handleDiscardSuspect: PropTypes.func.isRequired,
};
